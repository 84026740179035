:root {
  --color-red: #ff1720;
  --color-white: #ffffff;
  --color-gray: #a7a9ac;
  --color-black: #191414;
  --color-pink: #FEEFF0;
  --color-primary: var(--color-red);
  --color-secondary: var(--color-white);
  --color-primary-text: var(--color-black);
  --color-secondary-text: var(--color-red);
  --color-tertiary-text: var(--color-gray);
  --color-primary-background: var(--color-gray);
  --color-secondary-background: var(--color-black);
  --color-tertiary-background: var(--color-white);
  --font-syne: 'Syne', sans-serif;
  --font-zillaslab: 'Zilla Slab', sans-serif;
  --font-silkscreen: 'Silkscreen', fantasy;
  --font-six-caps: 'Six Caps', sans-serif;
  --font-dela: 'Dela Gothic One', sans-serif;
  --body-font-family: var(--font-syne);
  --quote-font-family: var(--font-zillaslab);
  --quote-font-weight: 300;
  --quote-font-style: italic;
  --quote-mobile-font-size: 27px;
  --quote-mobile-line-height: 1.4;
  --quote-desktop-font-size: 36px;
  --quote-desktop-line-height: 1.4;
  --subtitle-font-family: var(--font-syne);
  --subtitle-mobile-font-size: 22px;
  --subtitle-mobile-line-height: 1.4;
  --subtitle-desktop-font-size: 24px;
  --subtitle-desktop-line-height: 1.4;
  --h4-mobile-font-size: 18px;
  --h4-mobile-line-height: 1.2;
  --h4-desktop-font-size: 24px;
  --h4-desktop-line-height: 1.2;
  --h4-font-family: var(--font-silkscreen);
  --h3-mobile-font-size: 37.5px;
  --h3-mobile-line-height: 1.15;
  --h3-desktop-font-size: 50px;
  --h3-desktop-line-height: 1.15;
  --h3-font-family: var(--font-six-caps);
  --h2-mobile-font-size: 30px;
  --h2-mobile-line-height: 1.15;
  --h2-desktop-font-size: 50px;
  --h2-desktop-line-height: 1.15;
  --h2-font-family: var(--font-dela);
  --h1-mobile-font-size: 45px;
  --h1-mobile-line-height: 1.05;
  --h1-desktop-font-size: 120px;
  --h1-desktop-line-height: 1;
  --h1-font-family: var(--font-dela);
  --panel-desktop-font-size: 255px;
  --panel-tablet-font-size: 170px;
  --panel-mobile-font-size: 100px;
  --panel-desktop-line-height: 1;
  --panel-font-family: var(--font-dela);
  --body-font-size: 18px;
  --body-line-height: 1.5;
  --button-text-font-family: var(--font-silkscreen);
  --button-text-font-size: 14px;
  --button-text-line-height: 1;
  --link-text-font-family: var(--font-syne);
  --link-text-font-size: 16px;
  --link-text-line-height: 1.5;
  --space-mobile-1: 50px;
  --space-mobile-2: 75px;
  --space-mobile-3: 100px;
  --space-mobile-4: 125px;
  --space-mobile-5: 150px;
  --space-desktop-1: 50px;
  --space-desktop-2: 75px;
  --space-desktop-3: 100px;
  --space-desktop-4: 125px;
  --space-desktop-5: 150px;
  --space-desktop-6: 175px;
  --space-desktop-7: 200px;
  --space-desktop-8: 250px;
  --layout-max-width: 1420px;
  --space-unit-1: calc((var(--space-mobile-1) / 2) - 5px);
  --space-unit-2: calc((var(--space-unit-1) * 2));
  --grid-sm: repeat(4, 1fr);
  --grid-md: repeat(8, 1fr);
  --grid-lg: repeat(12, 1fr);
  --gutter-mobile: 16px;
  --gutter-desktop: 20px;
}
